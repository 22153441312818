import { graphql } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { Divider, GetStarted, SectionWrapper } from "../components/sections";
import { BackToZone } from "../components/back-to-zone";
import { SiteContext } from "../components/global/site-context";
import { EyebrowPill } from "../components/serializers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase, faCloud, faCommentDollar, faMagnifyingGlassChart, faMoneyBillTrendUp,
  faWrench, faLightbulb, faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { cn, mapEdgesToNodes, truncateText } from "../lib/helpers";
import { ArrowRight } from "../components/icon/arrow-left";
import { pageDocPath } from "../../helpers";
import { SparklesIconOnly } from "../components/icon/sparkles-only";
import { SIGNUP_PHRASE, SIGNUP_URL } from "../components/sections/snowflake/navigation";
import { ImageBuilder } from "../components/global/image-builder";
import { AccountCard } from "../components/sections/account-list";
import { PillHeader } from "../components/sections/wrapper";

import * as styles from "../components/global/global.module.css";

export const query = graphql`
  query AccountResearchTemplateQuery ($id: String!) {
    doc: notion(id: { eq: $id }) {
      id
      title
      properties {
        Business_Model___detail {
          value
        }
        Business_Model___summary {
          value
        }
        Company_website {
          value
        }
        Competition___detail {
          value
        }
        Earnings_Call_Summary___detail {
          value
        }
        Competition___summary {
          value
        }
        Earnings_Call_Summary___summary {
          value
        }
        Employees {
          value
        }
        Funding_stage {
          value
        }
        Hiring_Trends___detail {
          value
        }
        Hiring_Trends___summary {
          value
        }
        Industry {
          value
        }
        Investors___Funding___detail {
          value
        }
        Investors___Funding___summary {
          value
        }
        Product_Overview___detail {
          value
        }
        Product_Overview___summary {
          value
        }
        Revenue_range {
          value
        }
        Sales_Engagement_Strategy___detail {
          value
        }
        Sales_Engagement_Strategy___summary {
          value
        }
        Technology_Stack___detail {
          value
        }
        Technology_Stack___summary {
          value
        }
      }
    }
    allAccounts: allNotion (
      limit: 12,
      filter: {
        properties: { Industry: { value: { ne: "Unknown" } } }
      }
    ) {
      edges {
        node {
          id
          title
          properties {
            Product_Overview___summary {
              value
            }
            Company_website {
              value
            }
            Industry {
              value
            }
          }
        }
      }
    }
  }
`;

const AccountResearchTemplate = ({ data, pageContext }) => (
  <LayoutContainer doc={{ ...data.doc, _type: "account", backgroundColor: "lightLavender" }}>
    <PageHeadMeta
      title={
        `${data.doc?.title}${pageContext.detail ? ` ${PAGES.find(p => p.id === pageContext.detail).title.toLowerCase()
          } via RoomieAI™` : " account research plan"}`
      }
      description={
        truncateText({
          str: data.doc.properties?.[
            pageContext.detail
              ? `${PAGES.find(p => p.id === pageContext.detail)?.content}___summary`
              : "Product_Overview___summary"
          ].value,
          max: 140
        })
      }
      // image={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
      doc={data.doc}
    />
    <AccountResearchPage
      {...data.doc}
      allAccounts={data.allAccounts}
      detail={pageContext.detail}
    />
  </LayoutContainer>
);

export default AccountResearchTemplate;

// see: gatsby-node.js
const PAGES = [
  {
    title: "Business model",
    id: "business-model",
    icon: faLightbulb,
    content: "Business_Model",
    related: ["hiring-trends", "earnings-call-news"]
  },
  {
    title: "Investors & funding",
    id: "investors-funding",
    icon: faMoneyBillTrendUp,
    content: "Investors___Funding",
    related: ["product-overview", "business-model"]
  },
  {
    title: "Product overview",
    // menuTitle: "Product",
    id: "product-overview",
    icon: faWrench,
    content: "Product_Overview",
    related: ["business-model", "competitors"]
  },
  {
    title: "Competitors",
    id: "competitors",
    icon: faMagnifyingGlassChart,
    content: "Competition",
    related: ["investors-funding", "business-model"]
  },
  {
    title: "Hiring trends",
    id: "hiring-trends",
    icon: faBriefcase,
    content: "Hiring_Trends",
    related: ["earnings-call-news", "investors-funding"]
  },
  {
    title: "Earnings call & news",
    id: "earnings-call-news",
    icon: faCommentDollar,
    content: "Earnings_Call_Summary",
    related: ["hiring-trends", "investors-funding"]
  }
];

const FIELDS = [
  // { title: "Website", content: "Company_website" },
  { title: "Industry", content: "Industry" },
  { title: "Funding", content: "Funding_stage" },
  { title: "Team size", content: "Employees" },
  { title: "Revenue", content: "Revenue_range" },
];

const PAGE_SIZE = null;

const AccountResearchPage = (props) => {
  const { title, detail, allAccounts } = props;
  const site = useContext(SiteContext);

  const promoMessage2 = `Identify buyers at ${title} and connect.`
  const promoMessage = `Get 50+ buying signals for your account like ${title}. Enrich contacts and know when to engage.`;

  return (
    <>
      <SectionWrapper container={PAGE_SIZE} padding="top">
        <div className="grid md:grid-cols-3/5-2/5 md:gap-8 lg:grid-cols-2/3-1/3 lg:gap-24 max:gap-12">
          <div className="mb-4 lg:mb-0">
            {detail ? (
              <BackToZone to={pageDocPath({ ...props, _type: "account" })} text={`More about ${title}`} />
            ) : (
              <BackToZone to="/research/" text="See all Forbes Cloud 100" />
            )}
            <h1 className="text-6xl font-medium tracking-tight mt-1 mb-4">
              {detail
                ? `${title} ${PAGES.find((p) => p.id === detail).title.toLowerCase()}`
                : `${title} account research plan`}
            </h1>
            <a
              href="/product/ai/"
              className="text-xs sm:text-sm mt-2 mb-4 font-medium flex items-center gap-1.5 text-auxiliary hover:text-link"
            >
              <SparklesIconOnly /> Powered by RoomieAI™
            </a>
            {/* {props.detail && (
              <p className="my-4 leading-snug max-w-screen-sm">
                {props.properties?.[
                  `${PAGES.find(p => p.id === detail).content}___summary`
                ]?.value}
              </p>
            )} */}
            <div className={cn(
              "sm:max-w-3/4 md:max-w-none lg:max-w-3/4 text-secondary mb-6",
              // detail && "text-sm bg-dark-2 border border-dark-5 p-4 rounded-xl"
            )}>
              <p className="leading-snug max-w-screen-sm">
                Get a complete view of firmographics, product, competitors,
                hiring trends, and more to inform a tailored engagement plan.
              </p>
            </div>
          </div>
          <div className="mb-8">
            <MetaDetails {...props} />
          </div>
        </div>
      </SectionWrapper>

      {detail ? (
        <>
          <SectionWrapper container={PAGE_SIZE} padding="none">
            <div className="border border-link sm:p-3 rounded-xl text-lg leading-relaxed mb-8">
              <ReportSections
                sections={[
                  {
                    ...PAGES.find(p => p.id === detail),
                    title: `${PAGES.find(p => p.id === detail).title} details`,
                    icon: faArrowCircleRight
                  }
                  || PAGES[0]
                ]}
                data={props}
                organization={title}
                detail={detail}
              />
            </div>
          </SectionWrapper >

          <SectionWrapper container={PAGE_SIZE} padding="none">
            <PromoCard
              headline="Buying signals & triggers"
              subhead={promoMessage}
              imageUrl="https://cdn.sanity.io/images/vt347z5x/production/28e54abe5dd8749bf02e0cf704b7d644b0ffd4a1-880x564.png"
            />
          </SectionWrapper>

          <SectionWrapper container={PAGE_SIZE} padding="tight">
            <ReportSections
              sections={
                PAGES.find(p => p.id === detail).related.map((r) => PAGES.find(f => f.id === r))
              }
              data={props}
              organization={title}
              showLinks={true}
            />
          </SectionWrapper>

          <SectionWrapper container={PAGE_SIZE} padding="bottom">
            <PromoCard
              headline="Key decision makers"
              subhead={promoMessage2}
              imageUrl="https://cdn.sanity.io/images/vt347z5x/production/e33ab305b813015025c4a9544ea874767d93b687-884x374.png"
            />
          </SectionWrapper>
        </>
      ) : (
        <>
          <StickyNav />

          <SectionWrapper container={PAGE_SIZE} padding="tight">
            <ReportSections
              sections={[PAGES[0], PAGES[1]]}
              data={props}
              organization={title}
              showLinks={true}
            />
          </SectionWrapper>

          <SectionWrapper container={PAGE_SIZE} padding="none">
            <PromoCard
              headline="Key decision makers"
              subhead={promoMessage2}
              imageUrl="https://cdn.sanity.io/images/vt347z5x/production/e33ab305b813015025c4a9544ea874767d93b687-884x374.png"
            />
          </SectionWrapper>

          <SectionWrapper container={PAGE_SIZE} padding="tight">
            <ReportSections
              sections={[PAGES[2], PAGES[3]]}
              data={props}
              organization={title}
              showLinks={true}
            />
          </SectionWrapper>

          <SectionWrapper container={PAGE_SIZE} padding="none">
            <PromoCard
              headline="Buying signals & triggers"
              subhead={promoMessage}
              imageUrl="https://cdn.sanity.io/images/vt347z5x/production/28e54abe5dd8749bf02e0cf704b7d644b0ffd4a1-880x564.png"
            />
          </SectionWrapper>

          <SectionWrapper container={PAGE_SIZE} padding="tight">
            <ReportSections
              sections={[PAGES[4], PAGES[5]]}
              data={props}
              organization={title}
              showLinks={true}
            />
          </SectionWrapper>

          <SectionWrapper container={PAGE_SIZE} padding="tight"></SectionWrapper>
        </>
      )}

      <SectionWrapper container={PAGE_SIZE} padding="bottom">
        <PillHeader
          header="Explore more accounts"
          className="mb-6"
        />
        <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2 mb-6">
          {(site.isNotMobile ? mapEdgesToNodes(allAccounts) : mapEdgesToNodes(allAccounts).slice(0, 6)).map(
            (n, i) => <AccountCard key={i} account={n} />
          )}
        </div>
        <BackToZone to="/research/" text={site.token("backToResearch")} />
      </SectionWrapper>

      <SectionWrapper container={PAGE_SIZE} background="mint">
        <GetStarted />
      </SectionWrapper>
    </>
  );
};

const StickyNav = () => (
  <div className="sticky z-50 bg-green-ultralight w-full" style={{ top: "3.5rem" }}>
    <SectionWrapper container={PAGE_SIZE} padding="none">
      <div className="flex flex-wrap text-dark-80">
        {PAGES.map(({ title, icon, menuTitle }, i) => (
          <a href={`#${title.replace(/\s+/g, "-").toLowerCase()
            }`} key={title} className={cn(
              "border-b-4 border-dark-10 mr-px pb-2 pl-1 pr-3 pt-5 hover:text-link hover:border-link font-medium",
              // i === 0 && "border-dark-80 text-primary font-medium"
            )}>
            {/* <FontAwesomeIcon icon={icon} className="mr-1 text-link" /> */}
            {menuTitle || title}
          </a>
        ))}
        <span className="flex-grow border-b-4 border-dark-5">
        </span>
      </div>
    </SectionWrapper>
  </div>
);

const PromoCard = ({ headline, subhead, imageUrl }) => (
  <a
    href={SIGNUP_URL}
    className="inline-block bg-gray-1000 hover:shadow-lg scale-100 hover:scale-101 transform transition-card duration-300 text-white rounded-xl p-8 grid md:grid-cols-3/5-2/5 xl:grid-cols-2 gap-8 items-center"
  >
    <div className="flex justify-start">
      <img src={imageUrl} alt={headline} width={440} />
    </div>
    <div>
      <h3 className="text-3xl md:text-5xl mb-4">{headline}</h3>
      <p className="text-light-80 text-lg mb-6 lg:mr-24 xl:mr-16">{subhead}</p>
      <p className="inline-block text-link hover:text-white font-medium flex items-center gap-2">
        {SIGNUP_PHRASE}
        <ArrowRight size={14} />
      </p>
    </div>
  </a>
);

const MetaDetails = (props) => (
  <div className="bg-black p-4 lg:p-6 text-white rounded-xl text-sm">
    <a
      href={props.properties?.Company_website.value}
      className="font-bold text-lg tracking-tight flex gap-3 items-center group hover:text-light-90"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ImageBuilder
        src={`https://logo.clearbit.com/${props.properties?.Company_website.value}`}
        width={40}
        className="rounded-lg scale-100 group-hover:scale-105 transform transition-card duration-100"
        alt={props.title}
      />
      {props.title}
    </a>
    <Divider className="border-light-20 my-4" padding="none" />
    {FIELDS.map((field, i) => (
      <div key={field.title} className="mt-4 grid grid-cols-1/4-3/4 md:grid-cols-1/3-2/3 lg:grid-cols-1/4-3/4 gap-3 border-b border-light-20 border-dashed pb-1">
        <p className="text-light-70 whitespace-nowrap">
          {field.title}
        </p>
        <p className="flex-grow">
          {props.properties?.[field.content]?.value || <span className="text-light-60">unknown</span>}
        </p>
      </div>
    ))}
  </div>
);

const ReportSections = ({ sections, data, organization, showLinks, detail }) => sections.length > 0 ? (
  <div className={cn(sections.length > 1 ? "grid lg:grid-cols-2 gap-8" : "")}>
    {sections.map(({ title, id, icon, content }, i) => (
      <div key={i} className="relative">
        {title && (
          <a
            id={title.replace(/\s+/g, "-").toLowerCase()}
            className="absolute -top-60 sm:-top-52 md:-top-44 lg:-top-36"
          ></a>
        )}
        <a
          href={showLinks ? `/research/${organization.replace(/\s+/g, "-").toLowerCase()}/${id}/` : null}
          className={cn(
            "relative block group bg-white p-4 py-5 sm:p-8 lg:p-4 lg:p-10 rounded-xl h-full",
            showLinks
              ? "pb-16 sm:pb-16 lg:pb-16 hover:shadow-lg hover:bg-light-70 transition-card duration-300"
              : "lg:pt-10 lg:pb-12"
          )} key={title}>
          {title && (
            <div className={cn(
              "text-xl font-semibold flex items-center mb-4 transition-color duration-200",
              showLinks ? "text-secondary group-hover:text-black" : "mb-6"
            )}>
              {icon && (
                <FontAwesomeIcon
                  icon={icon}
                  size="lg"
                  className="mr-3 max-h-8"
                />
              )}
              {title}
            </div>
          )}
          <div className={styles.reportSection}>
            {/* <Markdown>{data.properties?.[content]?.value}</Markdown> */}
            <pre className="whitespace-pre-wrap font-sans">
              {data.properties?.[
                `${content}${detail ? "___detail" : "___summary"}`
              ]?.value.replace(/\\n/g, "\n")}
            </pre>
          </div>
          {showLinks ? (
            <span
              href={`/research/${organization}/${id}/`}
              className="inline-block absolute bottom-4 lg:bottom-6 left-4 lg:left-6 text-link font-medium mt-12 flex items-center gap-1.5"
            >
              <ArrowRight size={14} /> Learn more
            </span>
          ) : (
            <a href={SIGNUP_URL} className="text-link text-base mr-12 sm:mr-0 mt-6 flex items-center gap-2 hover:text-black transition-color duration-200">
              <ArrowRight size={14} />
              Get a full {organization} engagement strategy with your free trial
            </a>
          )}
        </a>
      </div>
    ))}
  </div>
) : null;

const ChatMessage = ({ children, bot, delay, className }) => {
  const [visible, setVisible] = useState(!delay);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return (
    <div delay={delay}
      className={cn(
        "p-3 px-4 mb-3 rounded-xl flex items-start",
        bot
          ? "bg-link text-white rounded-bl-none"
          : "bg-dark-10 text-dark-80 self-end rounded-br-none font-medium",
        className
      )}
    >
      {visible ? children : null}
    </div>
  );
};

const Delay = ({ children, delay, className, renderEarly }) => {
  const [visible, setVisible] = React.useState(!delay);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return <div className={cn(
    "transition-opacity duration-500",
    visible ? "opacity-100" : "opacity-0",
    className
  )}>
    {visible || renderEarly ? children : null}
  </div>;
}